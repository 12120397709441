// extracted by mini-css-extract-plugin
export const photoThumbnail = "apartmentPage-module--photoThumbnail--3yF7Z";
export const photoThumbnail__active = "apartmentPage-module--photoThumbnail__active--3sujq";
export const apartmentPageContainer = "apartmentPage-module--apartmentPageContainer--2zxZs";
export const apartmentGallery = "apartmentPage-module--apartmentGallery--3zoIi";
export const apartmentPhoto = "apartmentPage-module--apartmentPhoto--28Alm";
export const thumbnailsBar = "apartmentPage-module--thumbnailsBar--2SEkn";
export const apartmentInfo = "apartmentPage-module--apartmentInfo--2jdgl";
export const apartmentInfo__header = "apartmentPage-module--apartmentInfo__header--ehCpA";
export const apartmentInfo__text = "apartmentPage-module--apartmentInfo__text--3HuhC";
export const apartmentInfo__button = "apartmentPage-module--apartmentInfo__button--3gqQo";