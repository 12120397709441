import * as React from "react";
import { graphql } from "gatsby"
import { useEffect, useState } from "react"
import Slider from "react-slick";
import MagicSliderDots from 'react-magic-slider-dots';
import { IContentfulImage } from "../../types/contentfulTypes"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import * as styles from './apartmentPage.module.scss';
import CalendarModal from "../components/CalendarModal/CalendarModal";
import { IContentfulApartment } from "../types/contentfulTypes"
import Layout from "../components/Layout/Layout"
import CalendarButton from "../components/CalendarButton/CalendarButton"

type Props = {
  data: {
    contentfulApartment: IContentfulApartment
  }
}

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
};

export const query = graphql`
  query ($slug: String! $locale: String){
    contentfulApartment(slug: {eq: $slug} node_locale: {eq: $locale}) {
      name
      node_locale
      description {
        raw
      }
      photos {
        file {
          url
        }
      }
      calendarId
      slug
    }
  }
`

const getScreenWidth = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth;
  }
  return null;
}

const ApartmentPage: React.FC<Props> = ({
  data: {
    contentfulApartment: {
      name,
      description,
      photos,
      calendarId,
      slug
    }
  },
}: Props) => {
  const slider = React.createRef<HTMLDivElement>();
  const [currentPhoto, setCurrentPhoto] = useState(0);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [screenWidth, setScreenWidth] = useState(getScreenWidth());

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(getScreenWidth())
    })
  }, [])

  return (
    <Layout
      imagesToPreload={photos && photos.map(item => item.file.url)}
    >
      <div className={styles.apartmentPageContainer}>
        <div className={styles.apartmentGallery}>
          <Slider
            {...settings}
            ref={slider}
            afterChange={(index) => {setCurrentPhoto(index)}}
            dots={screenWidth < 768}
            appendDots={ dots => {
              return <MagicSliderDots
              dots={dots}
              numDotsToShow={5}
              dotWidth={30}
              />
            }
          }
          >
            {
              photos && photos.map((item: IContentfulImage) => (
                <div key={item.file.url}>
                  <div className={styles.apartmentPhoto} style={{ backgroundImage: `url(${item.file.url})`}} />
                </div>

              ))
            }
          </Slider>
        </div>
        {
          screenWidth >= 768 && (
            <div className={styles.thumbnailsBar}>
              {
                photos && photos.map((photo: IContentfulImage, index) => (
                  <div
                    key={photo.file.url}
                    style={{ backgroundImage: `url(${photo.file.url})`}}
                    className={index === currentPhoto ? styles.photoThumbnail__active : styles.photoThumbnail}
                    onClick={() => {slider.current.slickGoTo(index)}}
                  />
                ))
              }
            </div>
          )
        }
        <div className={styles.apartmentInfo}>
          <h2 className={styles.apartmentInfo__header}>{name}</h2>
          {
            description && (
              <div
                className={styles.apartmentInfo__text}
                dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(description.raw)) }}
              />
            )
          }
          {
            calendarId && (
              <CalendarButton
                onClick={() => {
                  setCalendarVisible(true);
                  document.body.classList.add('scrollDisabled')
                }}
                className={styles.apartmentInfo__button}
              />
            )
          }
        </div>
        {calendarVisible && <CalendarModal apartmentId={slug} calendarId={calendarId} onClose={() => {setCalendarVisible(false)}} />}
      </div>
    </Layout>
  )
}

export default ApartmentPage;
